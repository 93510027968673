import Vue from "vue";
import { login, refreshToken, loggedUser } from "@/api/endpoints/auth";

export default {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    setUserData: (state, user) => {
      state.user = user;
    },
    loginSuccess: (state, user) => {
      state.user = user;
    },
    logout: (state) => {
      state.user = null;
    },
    setBillingData: (state, billingData) => {
      state.user.billing_data = billingData;
    },
    setPaymentMethod: (state, paymentMethod) => {
      state.user.card = paymentMethod;
    },
  },
  actions: {
    async LOGIN({ commit }, payload) {
      const response = await login(payload);

      // Store tokens
      Vue.prototype.$saveAccessToken(response.data.access_token);
      Vue.prototype.$saveRefreshToken(response.data.refresh_token);

      commit("loginSuccess", response.data.user);
      return response;
    },
    async LOAD_USER({ commit }, payload) {
      const response = await loggedUser(payload);
      commit("setUserData", response.data);
      return response;
    },
    async REFRESH_TOKEN(_, payload) {
      const response = await refreshToken(payload);

      // Update token
      Vue.prototype.$saveAccessToken(response.data.access);
      return response;
    },
    async LOGOUT({ commit }) {
      Vue.prototype.$removeTokens();
      localStorage.clear(); // Clear local storage for new sessions

      try {
        // Send sign out event to segment
        Vue.prototype.$segment.track("Sign out");

        // Reset segment metadata
        Vue.prototype.$segment.reset();
      } finally {
        commit("logout");

        // Reload the session to drop store data
        window.location.reload();
      }
    },
    SET_BILLING_DATA({ commit }, billingData) {
      commit("setBillingData", billingData);
    },
    SET_PAYMENT_METHOD({ commit }, paymentMethod) {
      commit("setPaymentMethod", paymentMethod);
    },
    SET_USER_DATA({ commit }, userData) {
      commit("setUserData", userData);
    },
  },
};
