import { countries } from "@/api/endpoints/countries";

export default {
  namespaced: true,
  state: {
    countries: {},
  },
  mutations: {
    setCountries: (state, { countries }) => {
      state.countries = countries;
    },
  },
  actions: {
    async LOAD_COUNTRIES({ commit }) {
      const response = await countries();
      commit("setCountries", { countries: response.data });
      return response;
    },
  },
};
