import { requestInventory } from "@/api/endpoints/inventory";
import { nextPage } from "@/api/endpoints/common";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    inventory: [],
    nextUrl: null,
  },
  mutations: {
    setInventoryData: (state, inventory) => {
      state.inventory = inventory;
    },
    setNextUrl: (state, nextUrl) => {
      state.nextUrl = nextUrl;
    },
    updateInventoryData: (state, inventory) => {
      state.inventory.push(...inventory);
    },
    updateInventoryItem: (state, itemMetadata) => {
      const index = state.inventory.findIndex(
        (item) => item.id === itemMetadata.id
      );
      let item = state.inventory[index];
      item = Object.assign(item, itemMetadata);

      // Update it
      state.inventory.splice(index, 1, item);
    },
    unsetInventoryData: (state) => {
      state.inventory = [];
    },
    unsetNextUrl: (state) => {
      state.nextUrl = null;
    },
  },
  actions: {
    async LOAD_INVENTORY({ commit, state }, payload) {
      const response = state.nextUrl
        ? await nextPage(state.nextUrl)
        : await requestInventory(payload);
      const mutation = Vue.prototype.$isEmpty(state.inventory)
        ? "setInventoryData"
        : "updateInventoryData";

      commit(mutation, response.data.results);
      commit("setNextUrl", response.data.next);
      return response;
    },
    UPDATE_INVENTORY_ITEM({ commit }, itemMetadata) {
      commit("updateInventoryItem", itemMetadata);
    },
    CLEAR_INVENTORY({ commit }) {
      commit("unsetInventoryData");
      commit("unsetNextUrl");
    },
  },
};
