export default [
  {
    path: "/",
    component: () => import("@/views/private/MainView.vue"),
    meta: {
      requiresAuth: true,
      onboarding: true,
    },
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () => import("@/views/private/DashboardView.vue"),
        meta: {
          contractSelector: true,
          title: "title.home",
          withOnlyData: true,
        },
      },
      {
        path: "documents",
        name: "DocumentsView",
        component: () => import("@/views/private/DocumentsView.vue"),
        meta: {
          contractSelector: true,
          title: "title.documents",
          withOnlyData: true,
        },
      },
      {
        path: "customer-documents",
        name: "CustomerDocumentsView",
        component: () => import("@/views/private/CustomerDocumentsView.vue"),
        meta: {
          contractSelector: false,
          title: "title.documents",
          withOnlyData: true,
        },
      },
      {
        path: "inventory",
        name: "InventoryView",
        component: () => import("@/views/private/InventoryView.vue"),
        meta: {
          contractSelector: true,
          title: "title.inventory",
          withOnlyData: true,
        },
      },
      {
        path: "delivery-request",
        name: "DeliveryRequestView",
        component: () => import("@/views/private/DeliveryRequestView.vue"),
        meta: {
          contractSelector: false,
          title: "title.delivery",
          withOnlyData: true,
          withActiveSubscriptionOnly: true,
        },
      },
      {
        path: "settings",
        name: "UserSettingsView",
        component: () => import("@/views/private/UserSettingsView.vue"),
        meta: {
          contractSelector: false,
          title: "title.settings",
        },
      },
      {
        path: "pickup-request",
        name: "PickupRequestView",
        component: () => import("@/views/private/PickupRequestView.vue"),
        meta: {
          contractSelector: false,
          title: "title.pickup",
          withOnlyData: true,
          withActiveSubscriptionOnly: true,
        },
      },
      {
        path: "orders",
        name: "SummaryOrdersView",
        component: () => import("@/views/private/SummaryOrdersView.vue"),
        meta: {
          contractSelector: true,
          title: "title.orders",
          withOnlyData: true,
        },
      },
    ],
  },
  {
    path: "/set-user-data",
    name: "SetNameView",
    component: () => import("@/views/private/SetNameView.vue"),
    meta: {
      requiresAuth: true,
      title: "title.complete",
    },
  },
  {
    path: "/recover-password/:uid/:token/",
    name: "RecoveryPasswordView",
    component: () => import("@/views/public/RecoveryPasswordView.vue"),
    meta: {
      requiresAuth: false,
      title: "title.recoveryPassword",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/LoginView.vue"),
    meta: {
      title: "title.login",
    },
  },
  {
    path: "/activate/:uid/:token/",
    name: "ActivateAccountView",
    component: () => import("@/views/public/ActivateAccountView.vue"),
    meta: {
      requiresAuth: false,
      forceDisplay: true,
      title: "title.activate",
    },
  },
  {
    path: "*",
    name: "PageNotFound",
    component: () => import("@/views/PageNotFoundView.vue"),
    title: "title.pageNotFound",
  },
];
