const state = {
  SPACE_MULTIPLIER: 1.5,
  objects: {
    totalSpace: 0,
    objectList: [
      {
        id: "m_box",
        units: 0,
        width: 50,
        height: 35,
        depth: 35,
        space_multiplier: 1.9,
        total: 0,
        room: "common",
      },

      {
        id: "suitcase",
        units: 0,
        width: 60,
        height: 70,
        depth: 30,
        total: 0,
        room: "common",
      },
      {
        id: "bed_160",
        units: 0,
        width: 200,
        height: 150,
        depth: 50,
        total: 0,
        room: "bedroom",
      },
      {
        id: "bed_80",
        units: 0,
        width: 200,
        height: 90,
        depth: 50,
        total: 0,
        room: "bedroom",
      },
      {
        id: "night_table",
        units: 0,
        width: 60,
        height: 50,
        depth: 40,
        total: 0,
        room: "bedroom",
      },
      {
        id: "drawer",
        units: 0,
        width: 100,
        height: 60,
        depth: 50,
        total: 0,
        room: "bedroom",
      },
      {
        id: "wardrobe_3",
        units: 0,
        width: 210,
        height: 150,
        depth: 60,
        total: 0,
        room: "bedroom",
      },

      {
        id: "sofa",
        units: 0,
        width: 240,
        height: 100,
        depth: 80,
        total: 0,
        room: "livingroom",
      },
      {
        id: "sofa_l",
        units: 0,
        width: 180,
        height: 120,
        depth: 160,
        total: 0,
        room: "livingroom",
      },

      {
        id: "dining_table",
        units: 0,
        width: 80,
        height: 150,
        depth: 50,
        total: 0,
        room: "livingroom",
      },
      {
        id: "chairs",
        units: 0,
        width: 80,
        height: 60,
        depth: 40,
        total: 0,
        room: "livingroom",
      },
      {
        id: "office_desk",
        units: 0,
        width: 75,
        height: 150,
        depth: 50,
        total: 0,
        room: "livingroom",
      },

      {
        id: "office_chair",
        units: 0,
        width: 80,
        height: 60,
        depth: 60,
        total: 0,
        room: "livingroom",
      },

      {
        id: "bookshelf",
        units: 0,
        width: 200,
        height: 100,
        depth: 50,
        total: 0,
        room: "livingroom",
      },

      {
        id: "appliances",
        units: 0,
        width: 80,
        height: 60,
        depth: 60,
        total: 0,
        room: "common",
      },
      {
        id: "fridge",
        units: 0,
        width: 200,
        height: 70,
        depth: 60,
        total: 0,
        room: "common",
      },

      {
        id: "microwave",
        units: 0,
        width: 30,
        height: 40,
        depth: 40,
        total: 0,
        room: "common",
      },

      {
        id: "tv",
        units: 0,
        width: 70,
        height: 110,
        depth: 20,
        total: 0,
        room: "livingroom",
      },

      {
        id: "small_object",
        units: 0,
        width: 80,
        height: 90,
        depth: 70,
        total: 0,
        room: "default",
      },

      {
        id: "medium_object",
        units: 0,
        width: 100,
        height: 160,
        depth: 90,
        total: 0,
        room: "default",
      },

      {
        id: "large_object",
        units: 0,
        width: 120,
        height: 240,
        depth: 100,
        total: 0,
        room: "default",
      },
    ],
  },
  addressList: [],
  selectedAddress: null,
  floor: "0",
  access: "street_level",
  date: null,
  furnitureDisassemble: false,
  furniturePacking: false,
  boxPacking: false,
  comment: "",
};
const mutations = {
  removeObject: (state, object) => {
    state.objects.objectList.remove(object);
  },
  addObject: (state, object) => {
    state.objects.objectList.push(object);
  },
  setFurniturePacking: (state, value) => {
    state.furniturePacking = value;
  },
  setBoxPacking: (state, value) => {
    state.boxPacking = value;
  },
  addAddress: (state, address) => {
    if (Array.isArray(address)) {
      state.addressList.push(...address);
    } else {
      let lastElement = state.addressList[state.addressList.length - 1];

      // Check if is not a manual
      if (
        lastElement &&
        Object.prototype.hasOwnProperty.call(lastElement, "type")
      ) {
        Object.assign(lastElement, address);
      } else {
        // Simply add it
        state.addressList.push(address);
      }
    }
  },
  setSelectedAddress: (state, address) => {
    state.selectedAddress = address;
  },
  setFloor: (state, floor) => {
    state.floor = floor;
  },
  setAccess: (state, access) => {
    state.access = access;
  },
  setDate: (state, date) => {
    state.date = date;
  },
  setComment: (state, comment) => {
    state.comment = comment;
  },
  setFurnitureDisassemble: (state, value) => {
    state.furnitureDisassemble = value;
  },
  resetState: (state) => {
    state.objects.objectList = state.objects.objectList.filter(
      (object) => object.room !== "personalized"
    );
    state.objects.objectList.forEach((object) => (object.units = 0));
    state.objects.totalSpace = 0;
    state.addressList = [];
    state.selectedAddress = null;
    state.floor = "0";
    state.access = "street_level";
    state.date = null;
    state.furnitureDisassemble = false;
    state.furniturePacking = false;
    state.boxPacking = false;
    state.comment = "";
  },
  updateObjectUnits: (state, payload) => {
    state["objects"]["objectList"].filter(
      (object) => object.id === payload.id
    )[0]["units"] = parseInt(payload.units) || 0;
  },
};
const actions = {
  createObject({ commit }, object) {
    commit("addObject", object);
  },
  removeObject({ commit }, object) {
    commit("removeObject", object);
  },
  addObject({ commit }, object) {
    commit("addObject", object);
  },
  setFurniturePacking({ commit }, value) {
    commit("setFurniturePacking", value);
  },
  setBoxPacking({ commit }, value) {
    commit("setBoxPacking", value);
  },
  addAddress({ commit }, address) {
    commit("addAddress", address);
  },
  setSelectedAddress({ commit }, address) {
    commit("setSelectedAddress", address);
  },
  setFloor({ commit }, value) {
    commit("setFloor", value);
  },
  setAccess({ commit }, value) {
    commit("setAccess", value);
  },
  setDate({ commit }, value) {
    commit("setDate", value);
  },
  setFurnitureDisassemble({ commit }, value) {
    commit("setFurnitureDisassemble", value);
  },
  resetState({ commit }) {
    commit("resetState");
  },
  updateObjectUnits({ commit }, object) {
    commit("updateObjectUnits", object);
  },
  setComment({ commit }, comment) {
    commit("setComment", comment);
  },
};

const getters = {
  selectedObjectList: (state) =>
    state["objects"]["objectList"].filter((object) => object.units > 0),
  allObjects: (state) => state,
  objectsAmount: (state) => state.objects_amount,
  totalSpace: (state) => {
    let totalSpace = 0;
    state.objects.objectList.forEach((object) => {
      totalSpace += Math.max(
        object.width *
          object.height *
          object.depth *
          object.units *
          (object.space_multiplier || state.SPACE_MULTIPLIER),
        0
      );
    });
    return parseFloat((totalSpace / 2 / 1e6).toFixed(2));
  },
  hasNegativeValues: (state) =>
    state["objects"]["objectList"].some(
      (object) =>
        object.units < 0 ||
        object.width < 0 ||
        object.height < 0 ||
        object.depth < 0
    ),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
