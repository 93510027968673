import { creditNotes } from "@/api/endpoints/creditNotes";
import { nextPage } from "@/api/endpoints/common";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    creditNotes: [],
    nextUrl: null,
    count: 0,
  },
  mutations: {
    setCreditNotesData: (state, creditNotes) => {
      state.creditNotes = creditNotes;
    },
    setNextUrl: (state, nextUrl) => {
      state.nextUrl = nextUrl;
    },
    setCount: (state, count) => {
      state.count = count;
    },
    updateCreditNotesData: (state, creditNotes) => {
      state.creditNotes.push(...creditNotes);
    },
    unsetCreditNotesData: (state) => {
      state.creditNotes = [];
    },
    unsetNextUrl: (state) => {
      state.nextUrl = null;
    },
    unsetCount: (state) => {
      state.count = null;
    },
  },
  actions: {
    async LOAD_CREDIT_NOTES({ commit, state }, payload) {
      const response = state.nextUrl
        ? await nextPage(state.nextUrl)
        : await creditNotes(payload);
      const mutation = Vue.prototype.$isEmpty(state.creditNotes)
        ? "setCreditNotesData"
        : "updateCreditNotesData";

      commit(mutation, response.data.results);
      commit("setNextUrl", response.data.next);
      commit("setCount", response.data.count);

      return response;
    },
    CLEAR_CREDIT_NOTES({ commit }) {
      commit("unsetNextUrl");
      commit("unsetCreditNotesData");
      commit("unsetCount");
    },
  },
};
