const state = {
  objects: {
    objectList: [],
  },
  addressList: [],
  selectedAddress: null,
  floor: "0",
  access: "street_level",
  date: null,
  furnitureAssemble: false,
  comment: "",
};
const mutations = {
  setObjectList: (state, list) => {
    state.objects.objectList.push(list);
  },
  removeObject: (state, object) => {
    state.objects.objectList = state.objects.objectList.filter(
      (item) => item.id !== object.id
    );
  },
  addObject: (state, object) => {
    state.objects.objectList.push(object);
  },
  addAddress: (state, address) => {
    if (Array.isArray(address)) {
      state.addressList.push(...address);
    } else {
      let lastElement = state.addressList[state.addressList.length - 1];

      // Check if is not a manual
      if (
        lastElement &&
        Object.prototype.hasOwnProperty.call(lastElement, "type")
      ) {
        Object.assign(lastElement, address);
      } else {
        // Simply add it
        state.addressList.push(address);
      }
    }
  },
  setSelectedAddress: (state, address) => {
    state.selectedAddress = address;
  },
  setFloor: (state, floor) => {
    state.floor = floor;
  },
  setAccess: (state, access) => {
    state.access = access;
  },
  setDate: (state, date) => {
    state.date = date;
  },
  setComment: (state, comment) => {
    state.comment = comment;
  },
  setFurnitureAssemble: (state, value) => {
    state.furnitureAssemble = value;
  },
  resetState: (state) => {
    state.objects.objectList = [];
    state.addressList = [];
    state.selectedAddress = null;
    state.floor = "0";
    state.access = "street_level";
    state.date = null;
    state.furnitureAssemble = false;
    state.comment = "";
  },
};
const actions = {
  setObjectList({ commit }, list) {
    commit("setObjectList", list);
  },
  removeObject({ commit }, object) {
    commit("removeObject", object);
  },
  addObject({ commit }, object) {
    commit("addObject", object);
  },
  addAddress({ commit }, address) {
    commit("addAddress", address);
  },
  setSelectedAddress({ commit }, address) {
    commit("setSelectedAddress", address);
  },
  setFloor({ commit }, floor) {
    commit("setFloor", floor);
  },
  setAccess({ commit }, access) {
    commit("setAccess", access);
  },
  setDate({ commit }, date) {
    commit("setDate", date);
  },
  setComment({ commit }, comment) {
    commit("setComment", comment);
  },
  setFurnitureAssemble({ commit }, value) {
    commit("setFurnitureAssemble", value);
  },
  resetState({ commit }) {
    commit("resetState");
  },
};

const getters = {
  allObjects: (state) => state,
  hasNegativeValues: (state) =>
    state["objects"]["objectList"].some(
      (object) =>
        object.amount < 0 ||
        object.width < 0 ||
        object.height < 0 ||
        object.depth < 0
    ),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
