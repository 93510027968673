import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import contracts from "./modules/contracts";
import countries from "./modules/countries";
import pickupWizard from "./modules/pickupWizard";
import deliveryWizard from "./modules/deliveryWizard";
import carousel from "./modules/carousel";
import inventory from "./modules/inventory";
import invoices from "./modules/invoices";
import creditNotes from "./modules/creditNotes";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    contracts,
    countries,
    pickupWizard,
    deliveryWizard,
    carousel,
    inventory,
    invoices,
    creditNotes,
  },
});
