export default {
  namespaced: true,
  state: {
    images: {},
    selectedItems: {},
  },
  mutations: {
    addImage: (state, { imageKey, imageValue }) => {
      state.images[imageKey] = imageValue;
    },
    clearImage: (state) => {
      state.images = {};
    },
    resetImage: (state, imageKey) => {
      delete state.images[imageKey];
    },
    selectItem: (state, imageKey) => {
      state.selectedItems[imageKey] = true;
    },
    unselectItem: (state, imageKey) => {
      delete state.selectedItems[imageKey];
    },
    clearSelectedItems: (state) => {
      state.selectedItems = {};
    },
  },
  actions: {
    SET_IMAGE({ commit }, payload) {
      commit("addImage", payload);
    },
    RESET_IMAGE({ commit }, payload) {
      commit("resetImage", payload.imageKey);
    },
    CLEAR_IMAGE({ commit }) {
      commit("clearImage");
    },
    SELECT_ITEM({ commit }, payload) {
      commit("selectItem", payload.imageKey);
    },
    UNSELECT_ITEM({ commit }, payload) {
      commit("unselectItem", payload.imageKey);
    },
    CLEAR_ITEMS({ commit }) {
      commit("clearSelectedItems");
    },
  },
};
