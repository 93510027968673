import { contracts, contract } from "@/api/endpoints/contract";

export default {
  namespaced: true,
  state: {
    contractsList: {},
    contractData: {},
    contractNextPage: null,
  },
  mutations: {
    setContractsList: (state, { list }) => {
      state.contractsList = list;
    },
    setContractData: (state, data) => {
      state.contractData = data;
    },
    addAddressToList: (state, datas) => {
      state.contractData.addresses.push(datas);
    },
  },

  actions: {
    async LOAD_CONTRACTS({ commit }, payload) {
      const response = await contracts(payload);
      commit("setContractsList", { list: response.data });
      return response;
    },
    async LOAD_CONTRACT({ commit }, payload) {
      const response = await contract(payload);
      commit("setContractData", response.data);
      return response;
    },
    UNSET_CONTRACT({ commit }) {
      commit("setContractData", {});
    },
  },

  getters: {
    getAdressList: (state) => {
      return state.contractData.addresses;
    },
  },
};
