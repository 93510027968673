import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import { initializeData } from "./loadData";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "item-active",
  routes,
});

/**
 * Guards to handle accordingly authenticated views
 */
router.beforeEach(async (to, from, next) => {
  // Set the window title
  if (to.meta && to.meta.title) {
    document.title = `${router.app.$options.i18n.t(to.meta.title)} - Box2box`;
  }

  // If it is an authenticated user, try getting first the token from localStorage
  const accessToken = Vue.prototype.$getToken("access-token");

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (accessToken) {
      await initializeData(accessToken);
      const onboardingRoute = to.matched.some((route) => route.meta.onboarding);
      const isEmptyData =
        to.meta.withOnlyData &&
        Vue.prototype.$isEmpty(store.state.contracts.contractData) &&
        to.name !== "Dashboard";

      const isNotActiveContract =
        to.meta.withActiveSubscriptionOnly &&
        !Vue.prototype.$isEmpty(store.state.contracts.contractData) &&
        store.state.contracts.contractData.subscription?.status !== "active" &&
        ["PickupRequestView", "DeliveryRequestView"].indexOf(to.name) !== -1;

      if (onboardingRoute) {
        if (store.state.auth.user.onboarding_finished) {
          if (isEmptyData || isNotActiveContract) {
            next({ name: "Dashboard" });
          } else {
            next();
          }
        } else {
          next({ name: "SetNameView" });
        }
      } else {
        if (isEmptyData || isNotActiveContract) {
          next({ name: "Dashboard" });
        } else {
          next();
        }
      }
    } else {
      next({ name: "Login" });
    }
  } else {
    const existsRoute = to.name !== "PageNotFound";

    if (existsRoute) {
      // Check if user is authenticated
      if (accessToken && !to.meta.forceDisplay) {
        // Before going ahead, trigger some checks to populate data in views
        await initializeData(accessToken);

        // Redirect to main view
        next({ name: "Dashboard" });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
