import { invoices } from "@/api/endpoints/invoices";
import { nextPage } from "@/api/endpoints/common";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    invoices: [],
    nextUrl: null,
    count: 0,
  },
  mutations: {
    setInvoicesData: (state, invoices) => {
      state.invoices = invoices;
    },
    setNextUrl: (state, nextUrl) => {
      state.nextUrl = nextUrl;
    },
    setCount: (state, count) => {
      state.count = count;
    },
    updateInvoicesData: (state, invoices) => {
      state.invoices.push(...invoices);
    },
    unsetInvoicesData: (state) => {
      state.invoices = [];
    },
    unsetNextUrl: (state) => {
      state.nextUrl = null;
    },
    unsetCount: (state) => {
      state.count = null;
    },
  },
  actions: {
    async LOAD_INVOICES({ commit, state }, payload) {
      const response = state.nextUrl
        ? await nextPage(state.nextUrl)
        : await invoices(payload);
      const mutation = Vue.prototype.$isEmpty(state.invoices)
        ? "setInvoicesData"
        : "updateInvoicesData";

      commit(mutation, response.data.results);
      commit("setNextUrl", response.data.next);
      commit("setCount", response.data.count);
      return response;
    },
    CLEAR_INVOICES({ commit }) {
      commit("unsetNextUrl");
      commit("unsetInvoicesData");
      commit("unsetCount");
    },
  },
};
