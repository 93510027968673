import Vue from "vue";
import store from "@/store";

export const initializeData = async (accessToken) => {
  // Check if we have user data
  if (!store.state.auth.user) {
    const decodedToken = Vue.prototype.$decodedToken(accessToken);

    await store.dispatch("auth/LOAD_USER", {
      id: decodedToken.user_id,
    });
  }

  // Get contracts data if needed
  if (Vue.prototype.$isEmpty(store.state.contracts.contractsList)) {
    await store.dispatch("contracts/LOAD_CONTRACTS");
  }

  // Retrieve specific contract data if it is already selected one or just the first one
  const contractId = store.state.contracts.contractsList[0]?.id;

  if (
    Vue.prototype.$isEmpty(store.state.contracts.contractData) &&
    contractId
  ) {
    await store.dispatch("contracts/LOAD_CONTRACT", {
      id: contractId,
    });
  }

  // Load countries data
  if (Vue.prototype.$isEmpty(store.state.countries.countries)) {
    await store.dispatch("countries/LOAD_COUNTRIES");
  }
};
